@import url('../../global.css');

.toolbar {
    background-color: #848484;
    height: 250px;
    width: 100%;
    background-image: url("../../assets/imgs/bg-qr.png");
    position: relative;
    display: flex;
    justify-content: center;
    background-position: center;
}

.img-profile {
    position: absolute;
    bottom: -45px;
}

.btn-image-profile {
    position: absolute;
    bottom: -65px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.size-pen {
    font-size: 1.5rem;
}

.camera-profile {
    font-size: 1.7rem;
}

.img-avatar {
    border-radius: 50%;
    width: 110px;
    height: 110px;
    object-fit: cover;
}

.nav-link:hover {
    color: #dc3545;
}

.sells:hover {
    color: #dc3545 !important;
}

.nav-link:focus {
    color: #dc3545;
}

.sells {
    text-decoration: none !important;
    color: #848484 !important;
    font-weight: 500 !important;
    font-family: 'Roboto' !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent !important;
}

.badge-outline {
    background-color: transparent !important;
    border: 1px solid #dc3545;
    margin-left: 10px;
}

.edit-icon {
    font-size: 1.5rem;
}

.modal-click {
    cursor: pointer;
}

.line {
    background: gray;
}
.text-grey{
    color: #999999 !important;
}