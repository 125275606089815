@import url('../../global.css');
.text-ashes {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #999999 !important;
}
.custom-pl {
    padding-left: 32px;
}

@media only screen and (max-width: 980px) {
    .w-75 {
        width: 100% !important;
    }
}