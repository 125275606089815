@import url('../../global.css');

p {
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
}

.steps {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

hr {
    background: white;
}

.logo-social {
    font-size: 2rem;
    cursor: pointer;
}