@import url('../../global.css');

.preview {
    border: 2px solid #848484;
    width: 300px;
    height: 300px;
}

.icons-modal {
    width: 80px;
}

@media only screen and (max-width: 600px) {
    .icons-modal {
        width: 50px;
    }
}