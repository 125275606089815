@import url('../../global.css');

.img-toolbar {
    height: 200px;
}

.toolbar-inter {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-position: center;
    background-color: #848484;
}
