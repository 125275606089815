@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Bold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.ttf');
}

.bg-color {
    background-color: #f8f5f5;
}

.bg-footer {
    background-color: #848484;
}

.bg-footer-publi {
    background-color: #848484;
    height: 80px;
    width: 100%;
    opacity: 1;
    border-top: 1px solid #9a9696;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

@media only screen and (max-width : 1100px){ /* You can edit the max-width value to match what you need */
  .bg-footer-publi {
    height: 100%;
    width: 100%;
    opacity: 1;
    border-top: 1px solid #9a9696;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top:30px; /* You can edit the value of the margin top as you need */
  }
}

body {
    min-height: calc(100vh - 86px);
    box-sizing: border-box;
    background-color: #f8f5f5;
}

.link {
    cursor: pointer;
}

.labels {
    text-transform: uppercase;
}

.buttons {
    text-transform: uppercase;
}

.title {
    text-transform: uppercase;
}

.nav-link-no-decoration {
    text-decoration: none;
}

.form-control, .form-select{
    box-shadow:0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
}
.form-control:focus, .form-select:focus {
    outline: rgba(0, 0, 0, 0) !important;
    border: rgba(0, 0, 0, 0)  !important;
    box-shadow:0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
}
.form-control:active, .form-select:focus {
    outline:rgba(0, 0, 0, 0)  !important;
    border: rgba(0, 0, 0, 0)  !important;
    box-shadow:0 0 0 0.25rem rgba(0, 0, 0, 0) !important;
}

.simple-page-text {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #999999 !important;
}
.simple-page-text-justified {
    padding-left: 32px;
}
.r-mob {
    display: none;
}
.r-mt-5 {
    margin-top: 32px;
}

@media only screen and (max-width: 600px) {
    .custom-m {
        margin: auto !important;
        width: 100%  !important;
    }    
    .custom-pt-32 {
        padding-top: 32px;
    }
    .custom-mt-16 {
        margin-top: 16px;
    }
    .r-mobile-mt {
        padding-top: 32px;
    }
    .r-mobile-mt:first-child {
        padding-top: 0 !important;
    }
    .r-mobile-pt  {
        padding-top: 32px;
    }
    .r-mob {
        display: block;
    }
    .r-desk{
        display: none;
    }
    .r-mt-5 {
        margin: 16px 0;
    }
    .r-mb {
        margin-bottom: 32px;
    }
}