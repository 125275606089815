@import url('../../global.css');

.nav-link {
    text-decoration: none;
    color: #B1B0AF;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.navbar {
    box-shadow: 0 5px 10px -2px rgba(153, 152, 152, 0.5);
    background-color: #f8f5f5;
}

.badge-test {
    margin-left: 16px !important;
}

.icon-size {
    font-size: 1.8rem;
    color: #848484;
}

.custom-ico-wallet {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.notif {
    position: absolute;
    width: 10px;
    height: 15px;
    border-radius: 50%;
}

.active {
    color: #dc3545 !important;
}

.lang-select {
    background-color: transparent;
    border: none;
    color: #848484;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.logo {
    margin-right: 10px;
    width: 45px;
}
.custom-txt {
    margin-right: 4px !important;
}

@media only screen and (max-width: 600px) {
    .nav-link {
      text-align: center;
    }
    .navbar-light .navbar-toggler {
        margin-right: 16px;
    }

    .logo-cont {
        margin-left: 10px;
    }
    .custom-ico-wallet {
        justify-content: center;
    }
}