.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #d1d1d1ad;
  }
  .loader {
    left: 50%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }

  .spinner-border {
    width: 5.5rem;
    height: 5.5rem;
    border-width: 5px;
  }

  @media only screen and (max-width: 600px) {
    .loader {
      left: 40%;
      top: 40%;
    }
  }