@import url('../../global.css');

p {
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
}

.steps {
    width: 60px;
    height: 60px;
    border-radius: 0 !important;
    object-fit: contain !important;
}

hr {
    background: white;
}

.mySwiper {
    max-height: 100px;
}

.swiper-slide.swiper-slide-active {
    height: unset !important;
}
.w-95 {
    width: 95% !important;
}
@media only screen and (max-width: 580px) {
    .w-sm {
        width: 85% !important;
    }
}

.logo-social {
    font-size: 2rem;
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    background: #AE0303 !important;
}

.SwiperSupport {
    max-height: 420px;
}
.custom-img{
   max-height: 120px;
   object-fit: contain !important;
}
.custom-support {
    max-height: 80px;
}
.custom-title-support {
    letter-spacing: 1.5pt;
}
@media only screen and (max-width: 600px) {
    .mySwiper {
        max-height: 215px !important;
    }
}