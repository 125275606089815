@import url('../../global.css');
@import url('react-input-range/lib/css/index.css');

.prev {
    font-family: 'OpenSans', sans-serif;
    font-weight: 500;
    color: #848484;
}

.image-nft {
    width: 100%;
    min-width: 2% !important;
    height: 500px;
    overflow: hidden;
}

#nft-placeholder {
    height: 500px;
    width: 500px;
}

#image-placeholder {
    height: 200px;
}

.select-svg:hover {
    cursor: pointer;
    opacity: 0.7;
}

.upload {
    position: relative;
    border: 3px dotted #848484;
    padding: 30px;
    height: 100%;
}

.upload-icon {
    font-size: 4rem;
    color: #848484;
}

.fileInput {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload:hover,
.upload.dragover {
    opacity: 0.6;
}

.control {
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #dc3545;
}

.mint {
    color: #999999;
    font-family: 'Roboto';
    font-weight: 600;
}

.input-range__track--active {
    background: #bb2d3b;
}

.input-range__slider {
    background: #bb2d3b;
    border: 1px solid #bb2d3b;
}

.input-range__track {
    transition: left 0.1s ease-out, width 0.1s ease-out;
}

.input-range__slider-container {
    transition: left 0.1s ease-out;
}

.drag {
    cursor: grab;
}
@media only screen and (max-width : 480px){ 
.customResponsivePoper{
    right: 32px !important;
}
}